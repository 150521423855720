import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import { FiPhoneCall } from "react-icons/fi";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";


function ServicesHome_2({ bgImg, serviceName, serviceText }) {
  const { rpdata } = useContext(GlobalDataContext);
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

  return (
    <>
      <section className="mt-20">
        <div className="flex justify-center -mt-12 md:justify-end absolute">
          <img
            className="object-cover w-20 h-20 border-4 border-yellow-600 rounded-full"
            alt="Testimonial avatar"
            src={rpdata?.dbPrincipal?.favicon}
          />
        </div>
        <div className="max-w-xs overflow-hidden bg-white rounded-lg border-2 border-black shadow-lg m-2">
          <div className="px-4 py-8">
            <h1 className="text-xl font-bold text-gray-800 uppercase ">
              {serviceName}
            </h1>
          </div>
            <p>{serviceText}</p>
          <img
            className="object-cover w-full h-48 mt-2"
            src={bgImg}
            alt="image_services"
          />

          <div className="flex items-center justify-between px-4 py-2 mt-0 bg2">
          <h1 className="text-lg font-bold text-white">View More</h1>
            <NavLink
              to={
                rpdata?.autoGntLandingFromService
                  ? `/${serviceName
                      .replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-")
                      .toLowerCase()}`
                  : "/services"
              }
              className="relative"
              onClick={goToTop}
            >
              {" "}
              <BsArrowUpRightCircleFill className="bg1 p-1 text-[32px] text-white rounded-full" />
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesHome_2;
